* {
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  background: #fff;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}

body::-webkit-scrollbar-thumb {
  background-color: #267dff;
}

.ant-notification-notice {
  color: white;
  padding: 12px 24px !important;
  &.ant-notification-notice-warning {
    background-color: #faad14;
  }
  &.ant-notification-notice-error {
    background-color: #de3d3d;
  }
  &.ant-notification-notice-success {
    background-color: #26ae33;
  }
  .ant-notification-notice-icon {
    display: none;
  }
  .ant-notification-notice-message {
    color: white !important;
    margin-bottom: 0px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: -0.24px;
    margin-bottom: 0px !important;
    margin-inline-start: 0px !important;
  }
  .ant-notification-notice-close {
    font-size: 16px !important;
    color: white !important;
    top: 14px !important;
  }
  .ant-notification-notice-with-icon {
    display: flex;
    align-items: center;
  }
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ant-form {
  .ant-form-item {
    margin-bottom: 32px;
    .ant-form-item-label {
      color: #1c1c1c;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}

.ant-btn-primary {
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  height: 52px;
  &.btn-dark {
    background: #1c1c1c;
    color: #fff;
    &:hover {
      background: #1c1c1c !important;
    }
    &:disabled {
      background: #1c1c1c !important;
      opacity: 0.5;
    }
  }
  &.btn-warning {
    background: #fd6900;
    color: #fff;
    height: 25px;
    font-size: 14px;
    padding: 0px 8px;
    &:hover {
      background: #fd6900 !important;
    }
    &:disabled {
      background: #fd6900 !important;
      opacity: 0.5;
    }
  }
}

.btn-google {
  border: 1px solid #d9d9d9 !important;
  color: #1e293b !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  img {
    width: 24px;
  }
}

.page-title {
  color: #1c1c1c;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 32px;
}

.box {
  border-radius: 8px;
  background: #fff;
  padding: 32px;
  @media all and (max-width: 768px) {
    padding: 16px;
  }
}

.actions-footer-modal {
  display: flex;
  gap: 8px;
  button.ant-btn {
    height: 40px;
  }
}

.loading-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.otp {
  div {
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    input {
      width: 60px !important;
      height: 60px;
      border-radius: 8px;
      border: 1px solid #d9d9db;
      overflow: hidden;
      color: #0f0f0f;
      text-align: center;
      text-overflow: ellipsis;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }
    @media all and (max-width: 768px) {
      margin-bottom: 24px;
      input {
        width: 32px !important;
        height: 32px;
        padding: 0;
      }
    }
  }
}

.ant-tabs {
  .ant-tabs-nav {
    margin-bottom: 20px;
    &::before {
      border-bottom: none;
    }
    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          padding: 12px 16px;
          @media all and (max-width: 768px) {
            padding: 8px;
          }
          .ant-tabs-tab-btn {
            color: #a3adbd;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            @media all and (max-width: 768px) {
              font-size: 14px;
            }
          }
          &.ant-tabs-tab-active,
          &:hover {
            border-bottom: none;
            border-radius: 8px;
            background: #1c1c1c;
            .ant-tabs-tab-btn {
              color: #fff;
            }
          }
        }
      }
    }
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 24px;
  }
}

.empty {
  width: 100%;
  padding: 80px 0px;
  margin: 0px auto;
  text-align: center;
  img {
    width: 100%;
    max-width: 243px;
  }
  color: #768091;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ant-modal-wrap {
  .ant-modal {
    .ant-modal-header {
      margin-bottom: 24px;
      .ant-modal-title {
        font-size: 24px;
      }
    }
  }
  &.modal-deposit {
    .modal-deposit-box {
      .qr-code {
        width: 234px;
        height: 234px;
        border-radius: 8px;
        border: 1px solid #ebedf1;
        padding: 10px;
        margin: 0 auto 16px;
        img {
          max-width: 100%;
        }
      }
      .account {
        margin: 0 auto;
        width: 258px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;
        .logo-bank {
          height: 60px;
          text-align: center;
          img {
            max-height: 100%;
          }
        }
        .account-info {
          .bank-name {
            color: #8e96a4;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .account-name {
            margin: 8px 0px;
            color: #1c1c1c;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .account-number {
            display: flex;
            align-items: center;
            color: #267dff;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            cursor: pointer;
            img {
              margin-left: 8px;
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}

.modal-center {
  .ant-modal-title {
    text-align: center;
  }
}

.loading-spin {
  text-align: center;
  padding: 40px 0px;
}

.row-info {
  .row-value {
    color: #1c1c1c;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 4px;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
  .row-label {
    color: #768091;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.deposit,
.DEPOSIT {
  color: #009b2f;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.WITHDRAW,
.INTERNAL_TRANSFER {
  color: #ff0b37;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.status-transaction {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 999px;
  background: #e6f5eb;
  width: 139px;
  margin: 0 auto;
  &.success {
    color: #009b2f;
  }
  &.pending {
    background: #fff1e6;
    color: #fd6900;
  }
  &.rejected {
    background: #ff0b37;
    color: white;
  }
  .anticon {
    font-size: 18px;
  }
}

.mini-box {
  max-width: 603px;
  width: 100%;
  padding: 40px;
  margin: 0 auto;
  background: #fff;
  position: relative;
  .title {
    color: #1c1c1c;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 40px;
  }
  .new {
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #ebedf1;
    background: #fff;
    display: flex;
    align-items: center;
    color: #1c1c1c;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    margin-bottom: 40px;
    .btn-new {
      border-radius: 8px;
      border: 1px solid #ebedf1;
      background: #f6f7f9;
      width: 64px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      margin-right: 8px;
    }
  }
  .step-list {
    .sl-title {
      margin-bottom: 16px;
      color: #8e96a4;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .sl-items {
      .sl-item {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        border-radius: 8px;
        padding: 8px;
        border: 1px solid #ebedf1;
        background: #fff;
        &:hover {
          background: #f6f7f9;
        }
        gap: 16px;
        cursor: pointer;
        &.sl-item-transfer {
          padding: 16px 24px;
        }
        img {
          height: 64px;
        }
        .sl-item-info {
          .sl-item-name {
            color: #1c1c1c;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 8px;
          }
          .sl-item-number {
            color: #8e96a4;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }
  .select-bank {
    padding-bottom: 32px;
    border-bottom: 1px solid #ebedf1;
    margin-bottom: 40px;
    .select-label {
      color: #1c1c1c;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 8px;
    }
  }
  .input-group {
    margin-bottom: 24px;
    .input-label {
      color: #1c1c1c;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 8px;
    }
    .input-label-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .input-label {
        margin-bottom: 0px;
      }
      margin-bottom: 8px;
    }
    .input-note {
      color: #8e96a4;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    input {
      border-radius: 8px;
      border: 1px solid #ebedf1;
      background: #f6f7f9;
    }
    .ant-input-number {
      width: 100%;
    }
    textarea {
      background: #f6f7f9;
    }
  }
  .checkbox-group {
    margin-bottom: 24px;
  }
  .action {
    margin-top: 40px;
    button {
      width: 100%;
    }
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    .info-label {
      color: #8e96a4;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .info-name {
      color: #1c1c1c;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .divider {
    margin: 16px 0px 24px;
    border-bottom: 1px solid #ebedf1;
  }
  .form-2fa {
    text-align: center;
  }
  .success {
    padding-top: 115px;
    img {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
    }
    .success-label {
      color: #009b2f;
      text-align: center;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 16px;
      z-index: 2;
      position: relative;
    }
    .success-value {
      color: #1c1c1c;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 40px;
      z-index: 2;
      position: relative;
    }
    @media all and (max-width: 400px) {
      padding-top: 90px;
      .success-label {
        font-size: 24px;
      }
      .success-value {
        font-size: 24px;
      }
    }
  }
  @media all and (max-width: 768px) {
    padding: 16px !important;
    .title {
      margin-bottom: 24px;
      font-size: 18px;
    }
    .new {
      margin-bottom: 24px;
      .btn-new {
        width: 40px;
        height: 40px;
      }
    }
    .step-list {
      .sl-items {
        .sl-item {
          img {
            height: 40px;
            width: 40px;
          }
          &.sl-item-transfer {
            padding: 8px;
          }
        }
      }
    }
    .info {
      margin-bottom: 16px;
    }
    .success {
      position: relative;
      img {
        width: 100%;
      }
    }
  }
}

.select-option {
  display: flex;
  align-items: center;
  .logo {
    margin-right: 16px;
    border-radius: 999px;
    border: 1px solid #ebedf1;
    background: #fff;
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 40px;
    }
  }
  .select-option-info {
    width: calc(100% - 80px);
    .select-option-short-name {
      color: #1c1c1c;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 8px;
    }
    .select-option-name {
      color: #8e96a4;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.spin-loading {
  margin-block: 32px;
  display: flex;
  height: 70px;
  justify-content: center;
  align-items: center;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  height: 32px !important;
}

.ant-layout-sider {
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    img {
      margin-top: 20px;
      width: 143px;
    }
  }
  .ant-menu {
    flex-grow: 1;
    max-height: calc(100vh - 486px);
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #267dff;
    }
    &::-webkit-scrollbar-track {
      background-color: #e7e7e7;
      border: 1px solid #cacaca;
    }
    .ant-menu-item {
      height: 52px;
      background: transparent !important;
      padding: 0px 20px !important;
      margin-inline: 20px;
      width: calc(100% - 40px);
      &:hover,
      &.ant-menu-item-selected {
        border-radius: 8px;
        background: #f6f7f9 !important;
      }
    }

    .ant-menu-item,
    .ant-menu-submenu {
      .ant-menu-item-icon {
        font-size: 24px;
        color: #768091;
      }
      .icon-normal {
        display: block;
        margin-right: 20px;
      }
      .icon-active {
        display: none;
        margin-right: 20px;
      }
      .ant-menu-title-content {
        color: #768091;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .ant-menu-submenu-arrow {
        color: #768091;
      }
      &.ant-menu-item-selected,
      &:hover {
        .icon-normal {
          display: none;
        }
        .icon-active {
          display: block;
        }
        .ant-menu-item-icon {
          color: #267dff;
        }
        .ant-menu-submenu-arrow {
          color: #267dff;
        }
        .ant-menu-title-content {
          color: #267dff;
        }
        ul li {
          .ant-menu-title-content {
            color: #267dff;
          }
        }
      }
      &.ant-menu-submenu-open {
        .icon-normal {
          display: none;
        }
        .icon-active {
          display: block;
        }
        .ant-menu-item-icon,
        .ant-menu-title-content,
        .ant-menu-submenu-arrow {
          color: #267dff;
        }
      }
      ul li {
        color: #62554c !important;
        .ant-menu-title-content {
          color: #62554c !important;
        }
        &.ant-menu-item-selected,
        &:hover {
          .ant-menu-title-content {
            color: #267dff !important;
          }
        }
      }
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        height: 52px;
        padding: 0px 20px !important;
        margin-inline: 20px;
        width: calc(100% - 40px);
        &:hover {
          border-radius: 8px;
          background: #f6f7f9 !important;
          .ant-menu-title-content,
          .ant-menu-submenu-arrow {
            color: #1c1c1c;
          }
        }
      }
      &.ant-menu-submenu-open {
        .ant-menu-submenu-title {
          border-radius: 8px;
          background: #f6f7f9 !important;
          .ant-menu-title-content,
          .ant-menu-submenu-arrow {
            color: #1c1c1c;
          }
        }
      }
    }

    ul.ant-menu-sub {
      overflow-y: hidden;
      margin: 0px 20px 0px 48px;
      border-left: 1px solid #d6dae1;
      background: transparent !important;
      .ant-menu-item-only-child {
        background: transparent !important;
        padding-right: 0 !important;
        width: calc(100% - 20px);
        &:hover,
        &.ant-menu-item-selected {
          background: #f6f7f9 !important;
        }
      }
    }
  }
  .logout {
    border-top: 1px solid #ebedf1;
    height: 80px;
    padding: 0px 16px;
    cursor: pointer;
    .logout-item {
      display: flex;
      align-items: center;
      color: #1c1c1c;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 16px 0px;
      img {
        height: 24px;
        width: 24px;
        margin: 0px 20px;
      }
    }
  }
  @media all and (max-width: 1199px) {
    display: none;
  }
}

.ant-drawer {
  height: -webkit-fill-available;
  .ant-layout-sider {
    height: -webkit-fill-available !important;
  }
}

.ant-drawer-body {
  padding: 0px !important;
}

.ant-table-wrapper .ant-table-cell {
  @media all and (max-width: 768px) {
    padding: 8px !important;
  }
}

.link {
  color: #8e96a4;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  @media all and (max-width: 540px) {
    justify-content: center;
  }
  span {
    color: #404959;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.ant-modal-content {
  @media all and (max-width: 768px) {
    padding: 16px !important;
  }
}

.form-item-otp {
  .label {
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 8px;
  }
  .otp div {
    justify-content: space-between;
  }
}
